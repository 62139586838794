import styled from 'styled-components';
import { motion } from 'framer-motion';
import * as StoreIcons from '../services/iconsStore';
import {
    cursorPointer,
    displayBlock,
    height24px,
    primaryColor,
    secondaryColor,
    transitionAll03Ease,
    width24px,
    sixthBackColor,
    flexContainerCenterCenter,
    flexContainerStartStart,
    flexContainerSpacebetweenFlexstart,
    flexColumn,
    whiteBackColor,
    fontSize14px,
    fontSize16px,
    fontSize18px,
    fontSize20px,
    fontSize25px,
    fontSize30px,
    width100per,
    fontSegoeUI,
    textAlignCenter,
    whiteColor,
    transitionAll05EaseInOut,
    fontBrolimoRegular,
    overflowHidden,
    seventhColor,
    lineSpacing17,
    transparentBackColor,
    height52px,
    width20px,
    height20px,
    width48px,
    height48px,
    width30px,
    height30px,
    height38px,
    height100Per,
    borderRadius30,
    sixthColorHex,
    zIndex1,
    flexContainerEndCenter,
    positionAbsolute,
    displayNone,
    blueColor,
    flexRow,
    positionRelative,
    flexContainerSpacebetweenCenter,
    width16px,
    height16px,
    fontBenzGroteskHeavy
} from './common';


interface ObjectsExtraProps {
    extrastyles?: React.CSSProperties;
    extramobilestyles?: React.CSSProperties;
    extratabletstyles?: React.CSSProperties;
}

export const BackIconNormal = styled(StoreIcons.BackIconNormal)`
    ${width20px};
    ${height20px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${primaryColor};

    &.focused {
        ${secondaryColor};
        ${cursorPointer};
    }

    &:hover {
        ${secondaryColor};
         ${cursorPointer};
    }
`;

export const ToolBoxContainer = styled(motion.div)`
    ${flexContainerCenterCenter};
    ${flexColumn};
    width: 30%;//32
    margin-bottom: 27px;
    margin-left: 1.5%;
    margin-right: 1.5%;

    @media (max-width: 768px) {// Mobile screen sizes
        margin-bottom: 8px;
        width: 23%%;
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        margin-bottom: 12px;
    }

`;

export const ToolBox = styled.div<ObjectsExtraProps>`
    ${flexContainerCenterCenter};
    // width: 98px;
    // height: 98px;
    padding: 3.7vh;
    ${width100per};
    // ${borderRadius30};
    ${sixthBackColor};
    ${cursorPointer};
    margin-bottom: 7px;
    border-radius: 20px;

    &.bgColor{
        ${whiteBackColor};
        border: 5px solid #ECECEC;
        padding: 4.3vh;
    }

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

    @media (max-width: 468px) {// Mini Mobile screen sizes
        // width: 44px;
        // height: 75px;
        // padding: 2vh;
        padding: 2.7vh;
        &.bgColor{
            padding: 3.3vh;
            // height: 75px;
        }
        ${width100per};
        // border-radius: 15px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 469px) and (max-width: 768px) {// Mobile screen sizes
        // width: 44px;
        // height: 98px;
        // padding: 3vh;
        padding: 4vh 3.2vh;
        &.bgColor{
            // height: 98px;
            padding: 5.4vh 3.7vh
        }
        ${width100per};
        // border-radius: 15px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1151px){
        // height: 66px !important;
        // height: 80px!important;
        // border-radius: 20px;
        padding: 2.7vh;
        &.bgColor{
            // height: 80px!important;
            padding: 3.7vh 2.7vh;
        }
    }

    @media (min-width: 1152px) and (max-width: 1666px) { // Tablet and laptop screen sizes
        // width: 66px;
        // height: 75px;
        // padding: 3.7vh;
        &.bgColor{
            // height: 75px;
            // padding: 4.5vh;
        }
        ${width100per};
        // border-radius: 20px;
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
    
`;

export const ToolBoxLabel = styled.span<ObjectsExtraProps>`
    // ${fontSize18px};
    ${height24px};
    ${width100per};
    ${fontSegoeUI};
    ${textAlignCenter};
    letter-spacing: 0px;
    font-size: 1vw;
    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

    @media (max-width: 768px) {// Mobile screen sizes
        //${fontSize14px};
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        //${fontSize16px};
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const TitleIcon = styled(StoreIcons.TitleIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const TextIcon = styled(StoreIcons.TextIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const GridIcon = styled(StoreIcons.GridIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const CameraIcon = styled(StoreIcons.CameraIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const PictureIcon = styled(StoreIcons.PictureIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const GifIcon = styled(StoreIcons.GifIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const PngIcon = styled(StoreIcons.PngIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const AudioIcon = styled(StoreIcons.AudioIcon)`
    ${width24px};
    ${height24px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
`;

export const BtnPublich = styled(motion.div) <ObjectsExtraProps>`
    ${width100per};
    ${flexContainerCenterCenter};
    height: 98px;
    ${borderRadius30};
    margin-top: 30px;
    background-color: #53CAFF ;
    ${whiteColor};
    ${fontSize30px};
    ${cursorPointer};
    ${fontBrolimoRegular};
    ${transitionAll05EaseInOut};

    &.pressed{
        // background-color: white;
        border: 1px solid rgba(0,0,0, 0.5);
        cursor: not-allowed;
        color: white;
        ${fontBenzGroteskHeavy};
    }

    &>img{
        height: 90px; 
    }

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

    @media (max-width: 768px) {// Mobile screen sizes
        height: 64px;
        border-radius: 15px;
        margin: auto;
        margin-top: 15px;
        ${fontSize18px};
        width: 60%;

        &>img{
            height: 60px;
        }

        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        height: 60px;
        border-radius: 20px;
        margin-top: 14px;
        ${fontSize20px};
        
        &>img{
            height: 55px;
        }

        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const ControllersContainer = styled(motion.div)`
    ${width100per};
    ${displayNone};
    ${zIndex1};
    ${positionAbsolute};
    ${flexContainerEndCenter};
    height: 50px;
    pointer-events: none;

    @media (max-width: 768px) {// Mobile screen sizes
        height: 30px;
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        height: 40px;
    }

`;

export const Controllers = styled.div`
    ${height100Per};
    ${whiteBackColor};
    ${flexContainerCenterCenter};
    height: 50px;
    padding: 5px 20px;
    border-top: 1px solid ${sixthColorHex};
    border-right: 1px solid ${sixthColorHex};
    border-radius: 0px 28px 0px 0px;
    pointer-events: auto;
            
    @media (max-width: 768px) {// Mobile screen sizes
        border-radius: 0px 8px 0px 0px;
        padding: 5px 10px;
        height: 30px;
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        border-radius: 0px 18px 0px 0px;
        padding: 5px 15px;
        height: 40px;
    }
`;

export const DropDownBlock = styled(motion.div) <ObjectsExtraProps>`
    ${borderRadius30};
    // padding: 25px 28px;
    ${flexContainerSpacebetweenFlexstart};
    height: auto;
    transition: all 0.5s ease;
    ${positionRelative};

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        border-radius: 10px;
        // padding: 8px 18px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        border-radius: 20px;
        // padding: 14px 24px;
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const DropDownList = styled(motion.div) <ObjectsExtraProps>`
    ${flexContainerStartStart};
    ${flexColumn};
    ${transitionAll05EaseInOut};
    ${overflowHidden};
    ${width100per};
    ${positionAbsolute};
    ${zIndex1};
    ${borderRadius30};
    // padding: 15px;
    transition: max-height 0.5s ease-in-out;

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        // max-height: 44px;
        border-radius: 10px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        // max-height: 66px;
        border-radius: 20px;
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const DropDownLabel = styled(motion.div)<ObjectsExtraProps>`
    height: 50px;
    ${width100per};
    ${fontSize25px};
    ${fontSegoeUI};
    ${lineSpacing17};
    ${seventhColor};
    ${transitionAll05EaseInOut};
    ${flexContainerCenterCenter};
    ${sixthBackColor};
    letter-spacing: 0px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-top: 1px solid rgba(0,0,0,0);
    border-right: 1px solid rgba(0,0,0,0);
    border-left: 1px solid rgba(0,0,0,0);

    &.default{
        margin-bttom: 0px;
        border: 0px;
        height: 60px;
    }

    &:hover {
        background-color: #FFFFFF;
        ${cursorPointer};
        border-bottom: 1px solid rgba(0,0,0,0.1);
        border-top: 1px solid rgba(0,0,0,0);
        border-right: 1px solid rgba(0,0,0,0.1);
        border-left: 1px solid rgba(0,0,0,0.1);;
        &.default{
            border: 0px;
            ${sixthBackColor};
        }
    }

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}
            
    @media (max-width: 768px) {// Mobile screen sizes
        ${fontSize16px};
        height: 40px;
        &.default{
            margin-bttom: 0px;
            border: 0px;
            height: 40px;
        }
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${fontSize18px};
        height: 45px;
        &.default{
            margin-bttom: 0px;
            border: 0px;
            height: 55px;
        }
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const ArrowUpIcon = styled(StoreIcons.ArrowUpIcon)`
    ${width20px};
    ${height20px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};
    ${positionAbsolute};
    ${zIndex1};
    margin-right: 11px;

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }

    @media (max-width: 768px) {// Mobile screen sizes
        margin-right: 7px;
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        margin-right: 9px;
    }
`;

export const ArrowDownIcon = styled(StoreIcons.ArrowDownIcon)`
    ${width20px};
    ${height20px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};
    ${positionAbsolute};
    ${zIndex1};
    margin-right: 11px;

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }

    @media (max-width: 768px) {// Mobile screen sizes
        margin-right: 7px;
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        margin-right: 9px;
    }
`;


export const DropDownBlockDiv = styled(motion.div) <ObjectsExtraProps>`
    ${borderRadius30};
    padding: 25px 28px;
    ${sixthBackColor};
    ${flexContainerSpacebetweenFlexstart};
    height: auto;
    transition: all 0.5s ease;

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        border-radius: 10px;
        padding: 8px 18px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        border-radius: 20px;
        padding: 14px 24px;
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const DropDownListDiv = styled(motion.div) <ObjectsExtraProps>`
    ${flexContainerStartStart};
    ${flexColumn};
    ${transitionAll05EaseInOut};
    ${overflowHidden};
    transition: max-height 0.5s ease-in-out;

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        max-height: 44px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        max-height: 66px;
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const DropDownLabelDiv = styled.div<ObjectsExtraProps>`
    height: 33px;
    margin-bottom: 9px;
    ${fontSize25px};
    ${fontSegoeUI};
    ${lineSpacing17};
    ${seventhColor};
    ${transitionAll05EaseInOut};
    letter-spacing: 0px;

    .default{
        margin-bttom: 0px;
    }

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}
            
    @media (max-width: 768px) {// Mobile screen sizes
        ${fontSize16px};
        height: 22px;
        margin-bottom: 5px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${fontSize18px};
        height: 28px;
        margin-bottom: 7px;
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const InputText = styled(motion.input) <ObjectsExtraProps>`
    ${width100per};
    height: 33px;
    padding-bottom: 5px;
    ${fontSize25px};
    ${fontSegoeUI};
    ${lineSpacing17};
    ${seventhColor};
    ${transitionAll05EaseInOut};
    ${transparentBackColor};
    letter-spacing: 0px;
    border: 0px;
    outline: none;

    &::placeholder{
        ${fontSize25px};
        ${fontSegoeUI};
        ${lineSpacing17};
        ${seventhColor};
    }

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        ${fontSize16px};
        height: 22px;
        padding-bottom: 3px;
        line-spacing: 13px;

        &::placeholder{
            ${fontSize16px};
            line-spacing: 13px;
        }

        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${fontSize18px};
        height: 28px;
        padding-bottom: 3px;
        line-spacing: 15px;

        &::placeholder{
            ${fontSize18px};
            line-spacing: 15px;
        }

        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const TextArea = styled(motion.textarea) <ObjectsExtraProps>`
    ${width100per};
    height: 380px;
    padding-bottom: 5px;
    ${fontSize25px};
    ${fontSegoeUI};
    ${lineSpacing17};
    ${seventhColor};
    ${transitionAll05EaseInOut};
    ${transparentBackColor};
    letter-spacing: 0px;
    border: 0px;
    outline: none;

    &::placeholder{
        ${fontSize25px};
        ${fontSegoeUI};
        ${lineSpacing17};
        ${seventhColor};
    }

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        ${fontSize16px};
        height: 180px;
        padding-bottom: 3px;
        line-spacing: 13px;

        &::placeholder{
            ${fontSize16px};
            line-spacing: 13px;
        }

        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${fontSize18px};
        height: 280px;
        padding-bottom: 3px;
        line-spacing: 15px;

        &::placeholder{
            ${fontSize18px};
            line-spacing: 15px;
        }

        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const MediaFile = styled(motion.textarea) <ObjectsExtraProps>`
    ${width100per};
    height: 380px;
    padding-bottom: 5px;
    ${fontSize25px};
    ${fontSegoeUI};
    ${lineSpacing17};
    ${seventhColor};
    ${transitionAll05EaseInOut};
    ${transparentBackColor};
    letter-spacing: 0px;
    border: 0px;
    outline: none;

    &::placeholder{
        ${fontSize25px};
        ${fontSegoeUI};
        ${lineSpacing17};
        ${seventhColor};
    }

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        ${fontSize16px};
        height: 180px;
        padding-bottom: 3px;
        line-spacing: 13px;

        &::placeholder{
            ${fontSize16px};
            line-spacing: 13px;
        }

        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${fontSize18px};
        height: 280px;
        padding-bottom: 3px;
        line-spacing: 15px;

        &::placeholder{
            ${fontSize18px};
            line-spacing: 15px;
        }

        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }
`;

export const DragAndDropAreaContainer = styled.div<ObjectsExtraProps>`
    ${width100per};
    ${height100Per};
    ${flexContainerCenterCenter};
    ${cursorPointer};
     ${borderRadius30};
    border: 2px dashed #ccc;

    ${({ extrastyles }) =>
        extrastyles &&
        Object.entries(extrastyles)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
            .join('')}

            
    @media (max-width: 768px) {// Mobile screen sizes
        border-radius: 10px;
        padding: 8px 18px;
        ${({ extramobilestyles }) =>
        extramobilestyles &&
        Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        border-radius: 20px;
        padding: 14px 24px;
        ${({ extratabletstyles }) =>
        extratabletstyles &&
        Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
            .toLowerCase()}: ${value} !important;`).join('')}
    }

`;

export const DragAndDropContent = styled(motion.div)`
    ${fontSize18px};
    ${secondaryColor};
    ${width100per};
    ${flexContainerCenterCenter};
    ${flexColumn};

    .content-media{
        ${width100per};
        ${flexContainerCenterCenter};
        ${height52px};
        >svg{
            ${width48px};
            ${height48px};
            margin: 3px;
        }
    }

    .content-text{
        height: 60px;
        width: 150px;
        ${width100per};
        ${flexContainerCenterCenter};
        ${fontSize25px};
        ${fontSegoeUI};
        ${lineSpacing17};
        ${seventhColor};
        ${textAlignCenter};
    }

    @media (max-width: 768px) {// Mobile screen sizes

        .content-media{
            ${height24px};
            >svg{
                ${width20px};
                ${height20px};
            }
        }

        .content-text{
            ${fontSize16px};
            height: 50px;
            width: 100px;
        }

    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes

        .content-media{
            ${height38px};
            >svg{
                ${width30px};
                ${height30px};
            }
        }

        .content-text{
            ${fontSize18px};
            height: 45px;
            width: 130px;
        }

    }
`;

export const IconBox = styled.span`
  ${flexContainerSpacebetweenCenter}
  ${flexRow};
  ${transitionAll05EaseInOut};
  ${cursorPointer};
  ${fontSize25px};
  ${fontSegoeUI};
  ${whiteColor};
  margin-right: 12px;
  font-weight: 100;
  > img {
    ${width30px};
    ${height30px};
  }

  &.miniImg{
    > img {
        ${width24px};
        ${height24px};
    }
  }

  @media (max-width: 768px) {// Mobile screen sizes
    ${fontSize14px};
    > img {
        ${width24px};
        ${height24px};
    }

    &.miniImg{
    > img {
        ${width20px};
        ${height20px};
        }
    }
  }
 
  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${fontSize18px};
    >img{
        ${width20px};
        ${height20px};
    }

    &.miniImg{
        > img {
            ${width16px};
            ${height16px};
        }
    }
  }

`;

export const MainCoverIcon = styled(StoreIcons.MainCoverIcon)`
    ${width30px};
    ${height30px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
         ${cursorPointer};
    }
       
    @media (max-width: 768px) {// Mobile screen sizes
        ${width20px};
        ${height20px};
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${width24px};
        ${height24px};
    }

    &.selected{
        ${blueColor};

        &.focused {
            ${blueColor};
            cursor: none;
        }

        &:hover {
            ${blueColor};
            cursor: inherit;
        }
    }
`;

export const QuickViewIcon = styled(StoreIcons.QuickViewIcon)`
    ${width30px};
    ${height30px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
        ${cursorPointer};
    }
       
    @media (max-width: 768px) {// Mobile screen sizes
        ${width20px};
        ${height20px};
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${width24px};
        ${height24px};
    }

    &.selected{
        ${blueColor};
        ${cursorPointer};

        &.focused {
            ${blueColor};
        }

        &:hover {
            ${blueColor};
        }
    }
`;

export const ChangeMediaIcon = styled(StoreIcons.ChangeMediaIcon)`
    ${width30px};
    ${height30px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
        ${cursorPointer};
    }
       
    @media (max-width: 768px) {// Mobile screen sizes
        ${width20px};
        ${height20px};
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${width24px};
        ${height24px};
    }

    &.selected{
        ${blueColor};
        ${cursorPointer};
        
        &.focused {
            ${blueColor};
        }

        &:hover {
            ${blueColor};
        }
    }
`;

export const DeleteIcon = styled(StoreIcons.DeleteIcon)`
    ${width30px};
    ${height30px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
        ${cursorPointer};
    }
       
    @media (max-width: 768px) {// Mobile screen sizes
        ${width20px};
        ${height20px};
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${width24px};
        ${height24px};
    }

    &.selected{
        ${blueColor};
        ${cursorPointer};
        
        &.focused {
            ${blueColor};
        }

        &:hover {
            ${blueColor};
        }
    }
`;

export const SortIcon = styled(StoreIcons.SortIcon)`
    ${width30px};
    ${height30px};
    ${displayBlock};
    ${transitionAll03Ease};
    ${seventhColor};

    &.focused {
        ${seventhColor};
        ${cursorPointer};
    }

    &:hover {
        ${seventhColor};
        ${cursorPointer};
    }
       
    @media (max-width: 768px) {// Mobile screen sizes
        ${width20px};
        ${height20px};
    }

    @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
        ${width24px};
        ${height24px};
    }

    &.selected{
        ${blueColor};
        ${cursorPointer};
        
        &.focused {
            ${blueColor};
        }

        &:hover {
            ${blueColor};
        }
    }
`;