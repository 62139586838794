import axios from "axios";
import * as INTERFACES from "../interfaces";
import * as UTILS from "./utils";

const API_BASE_URL =
  process.env.REACT_APP_ENV_MODE === process.env.REACT_APP_ENV_MODE_DEV
    ? process.env.REACT_APP_URL_DEV
    : process.env.REACT_APP_URL_PRD;
const LOGIN_PATH = process.env.REACT_APP_LOGIN_PATH || "";
const REFRESH_TOKEN_PATH = process.env.REACT_APP_REFRESH_TOKEN_PATH || "";
const GET_CATEGORIES_PATH = process.env.REACT_APP_GET_CATEGORIES_PATH || "";
const GET_CAREERS_PATH = process.env.REACT_APP_GET_CAREERS_PATH || "";
const GET_TERMSSERVICES_PATH =
  process.env.REACT_APP_GET_TERMSSERVICES_PATH || "";
const PROJECT_PATH = process.env.REACT_APP_PROJECT_PATH || "";
const GET_PROJECTS_PATH = process.env.REACT_APP_GET_PROJECTS_PATH || "";
const api = axios.create({
  baseURL: API_BASE_URL,
});

export const login = async (username: string, password: string) => {
  try {
    const response = await api.post(LOGIN_PATH, { username, password });
    return response.data as INTERFACES.LoginResponse;
  } catch (error: any) {
    console.error("error login ", error);
    return error.response.data as INTERFACES.ResponseError;
  }
};

export const refreshToken = async (refreshToken: string) => {
  try {
    const response = await api.post(REFRESH_TOKEN_PATH, { refreshToken });
    return response.data as INTERFACES.ResponseSuccess;
  } catch (error: any) {
    console.error("error login ", error);
    return error.response.data as INTERFACES.ResponseError;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const response = await api.post("/user/update-data/forgot-password", {
      email,
    });
    return response.data as INTERFACES.ResponseSuccess;
  } catch (error: any) {
    console.error("error login ", error);
    return error.response.data as INTERFACES.ResponseError;
  }
};

export const resetPassword = async (email: string, code: string) => {
  try {
    const response = await api.post("/user/update-data/code-password", {
      email,
      code,
    });
    return response.data as INTERFACES.ResponseSuccess;
  } catch (error: any) {
    console.error("error login ", error);
    return error.response.data as INTERFACES.ResponseError;
  }
};

export const changePassword = async (
  currentPassword,
  newPassword,
  confirmNewPassword
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.put(
      `/user/update-data/change-password`,
      { currentPassword, newPassword, confirmNewPassword },
      { headers }
    );
    return response.data as INTERFACES.ResponseSuccess;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.put(`/user/update-data/change-password`, {
          headers,
        });
        return response.data as INTERFACES.ResponseSuccess;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const getCategories = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.get(`${GET_CATEGORIES_PATH}/in`, { headers });
    return response.data as INTERFACES.CategoryResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.get(`${GET_CATEGORIES_PATH}/in`, {
          headers,
        });
        return response.data as INTERFACES.CategoryResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const getCareeres = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.get(`${GET_CAREERS_PATH}/in`, { headers });
    return response.data as INTERFACES.CategoryResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.get(`${GET_CAREERS_PATH}/in`, { headers });
        return response.data as INTERFACES.CategoryResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const updateCareer = async (id: string, data: any) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.put(`${GET_CAREERS_PATH}/in/${id}`, data, {
      headers,
    });
    return response.data as INTERFACES.CategoryResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.put(`${GET_CAREERS_PATH}/in/${id}`, data, {
          headers,
        });
        return response.data as INTERFACES.CategoryResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const addCareer = async (data: any) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.post(`${GET_CAREERS_PATH}/in`, data, {
      headers,
    });
    return response.data as INTERFACES.CategoryResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.post(`${GET_CAREERS_PATH}/in`, data, {
          headers,
        });
        return response.data as INTERFACES.CategoryResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const deleteCareer = async (id: string) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.delete(`${GET_CAREERS_PATH}/in/${id}`, {
      headers,
    });
    return response.data as INTERFACES.CategoryResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.delete(`${GET_CAREERS_PATH}/in${id}`, {
          headers,
        });
        return response.data as INTERFACES.CategoryResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const getTermsServices = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.get(GET_TERMSSERVICES_PATH, { headers });
    return response.data as INTERFACES.TermsServicesResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.get(GET_TERMSSERVICES_PATH, { headers });
        return response.data as INTERFACES.TermsServicesResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const addProject = async (data: any, config: any) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.post(PROJECT_PATH, data, {
      headers,
      onUploadProgress: config.onUploadProgress, // Pass the onUploadProgress callback from the caller
    });

    console.log("response", response);

    return response.data as INTERFACES.ProjectResponse;
  } catch (error: any) {
    console.error("error request add project befroe refreshed ", error);
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "multipart/form-data", // Change the content type to handle files correctly
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.post(PROJECT_PATH, data, {
          headers,
          onUploadProgress: config.onUploadProgress, // Pass the onUploadProgress callback from the caller
        });
        return response.data as INTERFACES.ProjectResponse;
      } catch (refreshError: any) {
        console.error("error request add project refreshed ", refreshError);

        // Check for error.response before accessing its properties
        if (refreshError.response && refreshError.response.data) {
          return refreshError.response.data as INTERFACES.ResponseError;
        } else if (refreshError.response && refreshError.response.message) {
          return {
            hasError: true,
            statusCode: refreshError.response.status,
            status: "Error",
            message: refreshError.response.message,
            detail: "No Detail",
            requestedAt: 0,
            responsedAt: 0,
          } as INTERFACES.ResponseError;
        } else {
          // Handle other types of errors
          return {
            hasError: true,
            statusCode: 500, // Use a default status code
            status: "Error",
            message: "An unexpected error occurred during token refresh",
            detail: "No Detail",
            requestedAt: 0,
            responsedAt: 0,
          } as INTERFACES.ResponseError;
        }
      }
    } else {
      console.error("error request add project ", error);
      return {
        hasError: true,
        statusCode: error?.response?.data?.statusCode,
        status: error?.response?.data?.status,
        message: typeof error === "string" ? error : error?.message,
        detail: error?.response?.data?.message
          ? error?.response?.data?.message
          : "No details from the server",
        requestedAt: error?.response?.data?.requestedAt,
        responsedAt: error?.response?.data?.responsedAt,
      } as INTERFACES.ResponseError;
    }
  }
};

export const editProject = async (data, projectCode, config) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data", // Change the content type to handle files correctly
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.put(`${PROJECT_PATH}${projectCode}`, data, {
      headers,
      onUploadProgress: config.onUploadProgress,
    });
    return response.data as INTERFACES.ProjectResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "multipart/form-data", // Change the content type to handle files correctly
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.put(`${PROJECT_PATH}${projectCode}`, data, {
          headers,
          onUploadProgress: config.onUploadProgress,
        });
        return response.data as INTERFACES.ProjectResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const updateHiddenStatusProject = async (id, data) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data", // Change the content type to handle files correctly
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.patch(
      `${PROJECT_PATH}update-data/${id}/${data}`,
      {},
      { headers }
    );
    return response.data as INTERFACES.ProjectResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "multipart/form-data", // Change the content type to handle files correctly
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.patch(
          `${PROJECT_PATH}update-data/${id}/${data}`,
          {},
          { headers }
        );
        return response.data as INTERFACES.ProjectResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const deleteProject = async (id) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data", // Change the content type to handle files correctly
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    const response = await api.delete(`${PROJECT_PATH}/${id}`, { headers });
    return response.data as INTERFACES.ProjectResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "multipart/form-data", // Change the content type to handle files correctly
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };
        const response = await api.delete(`${PROJECT_PATH}/${id}`, { headers });
        return response.data as INTERFACES.ProjectResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export const getProjectsByParams = async (
  PAGE_NAME: string,
  params: string
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UTILS.getAccessToken()}`,
    };

    let url = `${GET_PROJECTS_PATH}in/${PAGE_NAME}`;
    if (params) {
      url += `?${params}`;
    }

    const response = await api.get(`${url}`, { headers });
    return response.data as INTERFACES.ProjectResponse;
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      await UTILS.refreshAccessToken();
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UTILS.getAccessToken()}`,
        };

        let url = `${GET_PROJECTS_PATH}in/${PAGE_NAME}`;
        if (params) {
          url += `?${params}`;
        }

        const response = await api.get(`${url}`, { headers });
        return response.data as INTERFACES.ProjectResponse;
      } catch (error: any) {
        console.error("error login refreshed ", error);
        return error.response.data as INTERFACES.ResponseError;
      }
    } else {
      console.error("error login ", error);
      return error.response.data as INTERFACES.ResponseError;
    }
  }
};

export default api;
