import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/basics/PrivateRoute';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';
import GlobalStyle from './styles/global';
import { TermsServicesContext } from './essentials/TermsServicesContext';
import LoadingPage from './components/basics/LoadingPage';
import * as UTILS from './services/utils';
import * as INTERFACES from './interfaces';
import * as API from './services/api';

// Lazy-loaded components
const ProjectsPage = lazy(() => import('./pages/Projects'));
const ProductionsPage = lazy(() => import('./pages/Productions'));
const Project = lazy(() => import('./pages/Project'));
const Production = lazy(() => import('./pages/Production'));
const InfoPage = lazy(() => import('./pages/Info'));
const LoginPage = lazy(() => import('./pages/Login'));
const ChangePasswordPage = lazy(() => import('./pages/ChangePassword'));
const NotFoundPage = lazy(() => import('./pages/404'));

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const logoPath = '/icons/main logo.svg';

  const [modelsCRUD, setModelsCRUD] = useState<INTERFACES.ModelsCRUD | null>(null);
  const [permissionsRoles, setPermissionsRoles] = useState<{
    [role: string]: {
      [entity: string]: {
        [permission: string]: boolean;
      };
    };
  } | null>(null);
  const [userRoles, setUserRoles] = useState<{ [role: string]: string } | null>(null);
  const [statusCodes, setStatusCodes] = useState<{ [code: string]: string | number } | null>(null);
  const [pages, setPages] = useState<{ [page: string]: string } | null>(null);

  const theme = {
    colors: {
      primary: '#fff',
    },
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      y: -50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: 50,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.3,
  };

  const authenticate = async () => {
    await getTermsAndServices();
  };

  useEffect(() => {
    const fetchData = async () => {
      const checkIfLoggedIn = UTILS.getAccessToken();
      if (checkIfLoggedIn) {
        const expirationTime = UTILS.getAccessTokenExpiration(checkIfLoggedIn);
        if (expirationTime && expirationTime > Date.now()) {
          await getTermsAndServices();
        } else {
          setIsLoggedIn(false);
          setIsLoading(false);
          UTILS.removeAccessToken();
        }
      } else {
        setIsLoggedIn(false);
        setIsLoading(false);
        UTILS.removeAccessToken();
      }

      setIsDataLoaded(true);
    };

    fetchData();
  }, []);

  const getTermsAndServices = async () => {
    try {
      const response = await API.getTermsServices();

      if (!response.hasError) {
        const successResponse = response as INTERFACES.TermsServicesResponse;
        const { modelsCRUD, permissionsRoles, userRoles, statusCodes, pages } = successResponse.detail;
        setModelsCRUD(modelsCRUD);
        setPermissionsRoles(permissionsRoles);
        setUserRoles(userRoles);
        setStatusCodes(statusCodes);
        setPages(pages);
        setIsLoggedIn(true);
        setIsLoading(false);
      } else {
        const errorResponse = response as INTERFACES.ResponseError;
        setIsLoggedIn(false);
        setIsLoading(false);
        UTILS.removeAccessToken();
        toast.error(errorResponse.message);
        toast(
          errorResponse.detail,
          {
            duration: 5000,
          }
        );
      }
    } catch (error: any) {
      setIsLoggedIn(false);
      setIsLoading(false);
      UTILS.removeAccessToken();
      console.error(error);
      toast.error('An error occurred while processing the request');
    }
  }

  if (!isDataLoaded) {
    return null;
  }

  return (
    <>
      <Router>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <TermsServicesContext.Provider
            value={{
              modelsCRUD,
              permissionsRoles,
              userRoles,
              statusCodes,
              pages,
            }}
          >
            <Toaster />
            <Routes>
              <Route path="/login" element={<Suspense fallback={<LoadingPage/>}><LoginPage authenticate={authenticate} /></Suspense>} />
              <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
                <Route path="/project" element={<Suspense fallback={<LoadingPage/>}><Project /></Suspense>} />
                <Route path="/production" element={<Suspense fallback={<LoadingPage/>}><Production /></Suspense>} />
                <Route path="/projects" element={<Suspense fallback={<LoadingPage/>}><ProjectsPage /></Suspense>} />
                <Route path="/productions" element={<Suspense fallback={<LoadingPage/>}><ProductionsPage /></Suspense>} />
                <Route path="/info" element={<Suspense fallback={<LoadingPage/>}><InfoPage /></Suspense>} />
                <Route path="/change-password" element={<Suspense fallback={<LoadingPage/>}><ChangePasswordPage /></Suspense>} />
                <Route path="/" element={<Suspense fallback={<LoadingPage/>}><ProjectsPage /></Suspense>} />
                <Route path="*" element={<Suspense fallback={<LoadingPage/>}><NotFoundPage /></Suspense>} />
              </Route>
            </Routes>
          </TermsServicesContext.Provider>
        </ThemeProvider>
        {isLoading && <LoadingPage />}
      </Router>
    </>
  );
};

export default App;
