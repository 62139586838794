import { createContext } from 'react';

export interface TermsServicesContextType {
    modelsCRUD: ModelsCRUD | null;
    permissionsRoles: {
        [role: string]: {
            [entity: string]: {
                [permission: string]: boolean;
            };
        };
    } | null;
    userRoles: {
        [role: string]: string;
    } | null;
    statusCodes: {
        [code: string]: string | number;
    } | null;
    pages: {
        [page: string]: string;
    } | null;
}

export const TermsServicesContext = createContext<TermsServicesContextType>({
    modelsCRUD: null,
    permissionsRoles: null,
    userRoles: null,
    statusCodes: null,
    pages: null,
});

export interface ModelsCRUD {
    MODELS: {
        [model: string]: ModelCRUD;
    };
    CRUD: {
        [crudAction: string]: string;
    };
}

interface ModelCRUD {
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
}
