import { css } from "styled-components";

////////////////COLORS HEX////////////////
export const primaryColorHex = "#bebebe";
export const secondaryColorHex = "#343434";
export const tertiaryColorHex = "#efefef";
export const quaternaryColorHex = "#F9F9F9 ";
export const quinaryColorHex = "#9B9B9B";
export const sixthColorHex = "#F2F6FB";
export const seventhColorHex = "#B2B2B2";
export const senaryColorHex = "#5A5A5A";
export const whiteColorHex = "#fff";
export const blackColorHex = "#000";
export const blackColor70Hex = "#707070";
export const blueColorHex = "#42A9DF";
////////////////COLORS////////////////
export const primaryColor = `
    color: ${primaryColorHex};
`;
export const secondaryColor = `
    color: ${secondaryColorHex};
`;
export const tertiaryColor = `
    color: ${tertiaryColorHex};
`;
export const quaternaryColor = `
    color: ${quaternaryColorHex};
`;
export const quinaryColor = `
    color: ${quinaryColorHex};
`;
export const sixthColor = `
    color: ${sixthColorHex};
`;
export const seventhColor = `
    color: ${seventhColorHex};
`;
export const senayColor = `
    color: ${senaryColorHex};
`;
export const whiteColor = `
    color: ${whiteColorHex};
`;
export const blackColor = `
    color: ${blackColorHex};
`;
export const black70Color = `
    color: ${blackColor70Hex};
`;
export const blueColor = `
    color: ${blueColorHex};
`;
////////////////BACKGROUND COLORS////////////////
export const primaryBackColor = `
    background-color: #bebebe;
`;
export const secondaryBackColor = `
    background-color: #343434;
`;
export const tertiaryBackColor = `
    background-color: #efefef;
`;
export const quaternaryBackColor = `
    background-color: #efefef;
`;
export const quinaryBackColor = `
    background-color: #F9F9F9 ;
`;
export const sixthBackColor = `
    background-color: #F2F6FB;
`;
export const sevenethBackColor = `
    background-color: #B2B2B2;
`;
export const senaryBackColor = `
    background-color: ${senaryColorHex} ;
`;
export const transparentBackColor = `
    background-color: transparent;
`;
export const black02BackColor = `
    background-color: rgba(0,0,0,0.2);
`;
export const black03BackColor = `
    background-color: rgba(0,0,0,0.3);
`;
export const black07BackColor = `
    background-color: rgba(0,0,0,0.7);
`;
export const whiteBackColor = `
    background-color: #fff;
`;
export const black70BackColor = `
    background-color: ${blackColor70Hex};
`;
////////////////FONTS////////////////
export const fontBrolimoRegular = `
    font-family: BrolimoRegular;
`;
export const fontBenzGroteskHeavy = `
    font-family: BenzGroteskHeavy;
`;
export const fontSegoeUI = `
    font-family: SegoeUI;
`;
////SIZE////
export const fontSize12px = `
    font-size: 12px;
`;
export const fontSize14px = `
    font-size: 14px;
`;
export const fontSize16px = `
    font-size: 16px;
`;
export const fontSize18px = `
    font-size: 18px;
`;
export const fontSize20px = `
    font-size: 20px;
`;
export const fontSize25px = `
    font-size: 25px;
`;
export const fontSize30px = `
    font-size: 30px;
`;
export const fontSize35px = `
    font-size: 35px;
`;
export const fontSize40px = `
    font-size: 40px;
`;
export const fontSize45px = `
    font-size: 45px;
`;
export const fontSize70px = `
    font-size: 70px;
`;
export const fontSize90px = `
    font-size: 90px;
`;
export const fontSize104px = `
    font-size: 104px;
`;
export const fontSize150px = `
    font-size: 150px;
`;
////WEIGHT////
export const fontWeightRegular = `
    font-weight: regular;
`;
export const fontWeightBold = `
    font-weight: bold;
`;
////////////////TRNAISION AND ANiMATION////////////////
////TRANSITION////
export const transitionAll03Ease = `
    transition: all 0.3s ease;
`;
export const transitionAll05EaseInOut = `
    transition: all 0.5s ease-in-out;
`;
////ANIMATION////
export const animationFadeInOpacityEaseInOutForwards = css`
  animation: fade-in 0.7s ease-in-out forwards;
`;
////////////////DISPLAY////////////////
export const displayBlock = `
    display: block;
`;
export const displayFlex = `
    display: flex;
`;
export const displayNone = `
    display: none;
`;
////FLEX////
export const flexContainerCenterCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const flexContainerCenterStart = `
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;
export const flexContainerStartCenter = `
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const flexContainerSpacebetweenBaseline = `
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;
export const flexContainerSpacebetweenFlexstart = `
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;
export const flexContainerEndCenter = `
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
export const flexContainerSpacebetweenCenter = `
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const inlineFlexContainerCenter = `
    display: inline-flex;
    align-items: center;
`;
export const flexContainerStartStart = `
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;
export const flexColumn = `
    flex-direction: column;
`;
export const flexRow = `
    flex-direction: row;
`;
////////////////WIDTH AND HEIGHT////////////////
////WIDTH////
export const widthAuto = `
    width: auto;
`;
export const width100per = `
    width: 100%;
`;
export const width50per = `
    width: 50%;
`;
export const width60per = `
    width: 60%;
`;
export const width70per = `
    width: 70%;
`;
export const width80per = `
    width: 80%;
`;
export const width90per = `
    width: 90%;
`;
export const width95per = `
    width: 95%;
`;
export const widthMin100per = `
    min-width: 100%;
`;
export const widthMax100per = `
    max-width: 100%;
`;
export const width16px = `
    width: 16px;
`;
export const width20px = `
    width: 20px;
`;
export const width24px = `
    width: 24px;
`;
export const width30px = `
    width: 30px;
`;
export const width38px = `
    width: 38px;
`;
export const width48px = `
    width: 48px;
`;
////HEIGHT////
export const height100Per = `
    height: 100%;
`;
export const heightMin100Per = `
    min-height: 100%;
`;
export const heightMin50Vh = `
    min-height: 50vh;
`;
export const height100Vh = `
    height: 100vh;
`;
export const heightMin100Vh = `
    min-height: 100vh;
`;
export const heightMax100Per = `
    max-height: 100%;
`;
export const height0px = `
    height: 0px;
`;
export const height16px = `
    height: 16px;
`;
export const height20px = `
    height: 20px;
`;
export const height24px = `
    height: 24px;
`;
export const height30px = `
    height: 30px;
`;
export const height38px = `
    height: 38px;
`;
export const heightMin38px = `
    min-height: 38px;
`;
export const height48px = `
    height: 48px;
`;
export const height52px = `
    height: 52px;
`;
export const height60px = `
    height: 60px;
`;
export const height100px = `
    height: 100px;
`;
export const height120px = `
    height: 120px;
`;
export const height140px = `
    height: 140px;
`;
export const height150px = `
    height: 150px;
`;
export const heightMin150px = `
    min-height: 150px;
`;
export const height200px = `
    height: 200px;
`;
export const heightMin372px = `
    min-height: 372px;
`;
export const heightMax500px = `
    max-height: 500px;
`;
export const margin0pxpadding0px = `
    margin: 0;
    padding: 0;
`;
////////////////LINE SPACING////////////////
export const lineSpacing17 = `
    line-spacing: 17px;
`;
export const lineSpacing20 = `
    line-spacing: 20px;
`;
export const lineSpacing24 = `
    line-spacing: 24px;
`;
export const lineSpacing30 = `
    line-spacing: 30px;
`;
export const overflowHidden = `
    overflow: hidden;
`;
////////////////BORDER////////////////
export const border1pxTertiaryColor = `
    border: 1px solid ${tertiaryColorHex}
`;
////////////////CURSOR////////////////
export const cursorPointer = `
    cursor: pointer;
`;
////////////////PADDING////////////////
export const padding0px = `
    padding: 0px;
`;
export const padding0px140px = `
    padding: 0px 140px;
`;
////////////////POSITION////////////////
export const positionRelative = `
    position: relative;
`;
export const positionAbsolute = `
    position: absolute;
`;
export const positionFixed = `
    position: fixed;
`;
////TOP////
export const top0 = `
    top:0;
`;
////BOTTOM////
export const bottom0 = `
    bottom:0;
`;
////LEFT////
export const left0 = `
    left:0;
`;
////////////////ZINDEX////////////////
export const zIndex0 = `
    z-index: 0;
`;
export const zIndex1 = `
    z-index: 1;
`;
export const zIndex2 = `
    z-index: 2;
`;
export const zIndex3 = `
    z-index: 3;
`;
export const zIndex998 = `
    z-index: 998;
`;
export const zIndex999 = `
    z-index: 999;
`;
////////////////OPACITY////////////////
export const opacity0 = `
    opacity: 0;
`;
export const opacity1 = `
    opacity: 1;
`;
////////////////IMAGE////////////////
export const backgroundSizeCover = `
    background-size: cover;
`;
export const backgroundSizeContain = `
    background-size: contain;
`;
export const backgroundPositionCenter = `
    background-position: center;
`;
////////////////TEXT-ALIGN////////////////
export const textAlignCenter = `
    text-align: center;
`;
////////////////BORDER RADIUS////////////////
export const borderRadius33 = `
    border-radius: 33px;
`;
export const borderRadius30 = `
    border-radius: 30px;
`;
