import { FiSearch } from "react-icons/fi";
import {
  AiOutlineCloseCircle,
  AiOutlineClose,
  AiOutlinePicture,
} from "react-icons/ai";
import {
  BsFillPlayCircleFill,
  BsPauseCircleFill,
  BsTextCenter,
  BsCameraVideo,
} from "react-icons/bs";
import { GrPlayFill, GrStopFill } from "react-icons/gr";
import { BiReset, BiSolidFileGif, BiSolidFilePng } from "react-icons/bi";
import {
  IoArrowBackCircleSharp,
  IoGridOutline,
  IoCopySharp,
} from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { MdTitle, MdPublishedWithChanges } from "react-icons/md";
import { FaRegFileAudio, FaSort, FaArrowUp, FaShareAlt } from "react-icons/fa";
import { LuView } from "react-icons/lu";
import { RxBoxModel } from "react-icons/rx";
import { RiExchangeBoxLine, RiDeleteBinFill } from "react-icons/ri";
import {
  VscTriangleLeft,
  VscTriangleUp,
  VscTriangleDown,
} from "react-icons/vsc";
import { PiWarningBold } from "react-icons/pi";
import { RiFullscreenLine } from "react-icons/ri";

export const SearchIcon: typeof FiSearch = FiSearch;
export const CloseIcon: typeof AiOutlineClose = AiOutlineClose;
export const CloseIconCircular: typeof AiOutlineCloseCircle =
  AiOutlineCloseCircle;
export const PlayVideoIconCircle: typeof BsFillPlayCircleFill =
  BsFillPlayCircleFill;
export const PauseVideoIconCircle: typeof BsPauseCircleFill = BsPauseCircleFill;
export const PlayVoiceIconCircle: typeof GrPlayFill = GrPlayFill;
export const PauseVoiceIconCircle: typeof GrStopFill = GrStopFill;
export const ResetIcon: typeof BiReset = BiReset;
export const BackIconCircle: typeof IoArrowBackCircleSharp =
  IoArrowBackCircleSharp;
export const BackIconNormal: typeof IoIosArrowBack = IoIosArrowBack;
export const ArrowUpIconCircle: typeof FaArrowUp = FaArrowUp;
export const CopyIcon: typeof IoCopySharp = IoCopySharp;
export const ShareIcon: typeof FaShareAlt = FaShareAlt;

export const TitleIcon: typeof MdTitle = MdTitle;
export const TextIcon: typeof BsTextCenter = BsTextCenter;
export const GridIcon: typeof IoGridOutline = IoGridOutline;
export const CameraIcon: typeof BsCameraVideo = BsCameraVideo;
export const PictureIcon: typeof AiOutlinePicture = AiOutlinePicture;
export const GifIcon: typeof BiSolidFileGif = BiSolidFileGif;
export const PngIcon: typeof BiSolidFilePng = BiSolidFilePng;
export const AudioIcon: typeof FaRegFileAudio = FaRegFileAudio;

export const ArrowDownIcon: typeof VscTriangleDown = VscTriangleDown;
export const ArrowLeftIcon: typeof VscTriangleLeft = VscTriangleLeft;
export const ArrowUpIcon: typeof VscTriangleUp = VscTriangleUp;

export const MainCoverIcon: typeof RxBoxModel = RxBoxModel;
export const QuickViewIcon: typeof LuView = LuView;
export const ChangeMediaIcon: typeof RiExchangeBoxLine = RiExchangeBoxLine;
export const DeleteIcon: typeof RiDeleteBinFill = RiDeleteBinFill;
export const SortIcon: typeof FaSort = FaSort;
export const WarningIcon: typeof PiWarningBold = PiWarningBold;
export const ChangePublishIcon: typeof MdPublishedWithChanges =
  MdPublishedWithChanges;
export const FullScreenIcon: typeof RiFullscreenLine = RiFullscreenLine;
