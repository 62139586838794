import React, { useEffect, useState } from 'react';
import { LogoImage } from '../../styles/topSection';
import { LoadingPageContainer } from '../../styles/global';

const LoadingPage: React.FC = () => {
  const logoPath = "/icons/logo.svg";

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!isReady) {
    return (
      <LoadingPageContainer>
        {/* <LogoImage src={logoPath} alt="Logo" /> */}
      </LoadingPageContainer>
    );
  }

  return null;
};

export default LoadingPage;
