import { motion } from 'framer-motion';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import {
  black70Color,
  blackColor,
  flexColumn,
  flexContainerEndCenter,
  flexContainerCenterCenter,
  flexContainerStartCenter,
  fontBenzGroteskHeavy,
  fontBrolimoRegular,
  fontSegoeUI,
  fontSize104px,
  fontSize14px,
  fontSize20px,
  fontSize25px,
  fontSize45px,
  fontSize70px,
  heightMin50Vh,
  lineSpacing30,
  margin0pxpadding0px,
  positionRelative,
  quinaryColorHex,
  secondaryColorHex,
  tertiaryColorHex,
  transitionAll03Ease,
  width100per,
  width80per,
  height100Vh,
  cursorPointer,

} from './common';
import { ControllersContainer } from './addProject';

interface ObjectsExtraProps {
  extrastyles?: React.CSSProperties;
  extramobilestyles?: React.CSSProperties;
  extratabletstyles?: React.CSSProperties;
  extralaptopstyles?: React.CSSProperties;
}

export const fadeInOpacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .hide-scrollbar {
    overflow: hidden;
  }

  @font-face {
    font-family: 'BrolimoRegular';
    src: url('/fonts/BrolimoRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* or optional */
  }

  @font-face {
    font-family: 'BenzGroteskHeavy';
    src: url('/fonts/BenzGroteskHeavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* or optional */
  }

  @font-face {
    font-family: 'SegoeUI';
    src: url('/fonts/segoeui.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* or optional */
  }

  //change the scroll bar
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${tertiaryColorHex};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${secondaryColorHex};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${quinaryColorHex};
  }

  @keyframes fade-in {
    ${fadeInOpacity}
  }

  @keyframes spin {
    ${spinAnimation}
  }

`;

export const NoDataBlock = styled(motion.div) <ObjectsExtraProps>`
  ${heightMin50Vh};
  ${width100per};
  ${flexContainerCenterCenter};
  ${fontBrolimoRegular};
  ${flexColumn};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}
  
  @media (max-width: 768px) {// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
`;

export const NoData = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  ${fontSize14px};
  ${flexContainerCenterCenter};
  ${fontBrolimoRegular};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
`;

export const Container = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  overflow: hidden;

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
  @media (min-width: 769px) and (max-width: 1150px) { // Tablet screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
  @media (min-width: 1151px) and (max-width: 1366px) { // Laptop screen sizes
    ${({ extralaptopstyles }) =>
    extralaptopstyles &&
    Object.entries(extralaptopstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const SubContainer = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 468px) {// Mini Mobile screen sizes
    ${({ extramobilestyles }) =>
      extramobilestyles &&
      Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 469px) and (max-width: 768px) {// Mobile screen sizes
   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const Section = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  ${flexContainerCenterCenter};
  ${flexColumn};
  ${fontBrolimoRegular};
  ${fontSize14px};
  ${positionRelative};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1150px) { // Tablet screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 1151px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extralaptopstyles }) =>
    extralaptopstyles &&
    Object.entries(extralaptopstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }


  &:hover > ${ControllersContainer}{
      ${flexContainerEndCenter};
  }

`;

export const SectionProject = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  ${flexContainerCenterCenter};
  ${flexColumn};
  ${fontBrolimoRegular};
  ${fontSize14px};
  ${positionRelative};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 893px) {// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 894px) and (max-width: 1150px) { // Tablet screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 1151px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extralaptopstyles }) =>
    extralaptopstyles &&
    Object.entries(extralaptopstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }


  &:hover > ${ControllersContainer}{
      ${flexContainerEndCenter};
  }

`;

export const BigTitle = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  ${flexContainerCenterCenter};
  padding: 10px 0px;
  ${fontBenzGroteskHeavy};
  ${fontSize104px};
  ${blackColor};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
    ${fontSize70px};

    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const NormalTitle = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  ${flexContainerStartCenter};
  ${fontBrolimoRegular};
  ${fontSize45px};
  ${black70Color};
  padding: 10px 0px;
  letter-spacing: 0px;

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {
   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  >img{
    ${cursorPointer};
  }

`;

export const ParagraphContainer = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  ${flexContainerCenterCenter};
  padding: 10px 0px;

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const Paragraph = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  padding: 10px 0px;
  ${fontSegoeUI};
  ${black70Color};
  ${lineSpacing30};
  ${fontSize25px};
  text-align:center;

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
    ${width80per};
    ${fontSize14px};

   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const ImageContainer = styled(motion.div) <ObjectsExtraProps>`
  ${width100per};
  ${flexContainerCenterCenter};
  padding: 10px 0px;

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media(max-width: 768px){// Mobile screen sizes
   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const EmptySpaceHorizontal= styled.div <ObjectsExtraProps>`
  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media(max-width: 768px){// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
  
  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
`;

export const UL = styled.ul<ObjectsExtraProps>`
  ${margin0pxpadding0px};
  ${({ extrastyles }) =>
  extrastyles &&
  Object.entries(extrastyles)
    .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
    .join('')}

  @media(max-width: 768px){// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
`;

export const LI = styled(motion.li) <ObjectsExtraProps>`
  ${margin0pxpadding0px};
  list-style-type: none;
  ${fontBenzGroteskHeavy};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media(max-width: 768px){// Mobile screen sizes
  ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const IMG = styled(motion.img) <ObjectsExtraProps>`
  ${width100per};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media(max-width: 768px){// Mobile screen sizes
   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const LINKME = styled(motion.a) <ObjectsExtraProps>`
  text-decoration: none;
  ${transitionAll03Ease};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media(max-width: 768px){// Mobile screen sizes
   ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  &:hover{
    ${blackColor};
    >span{
      ${blackColor};
    }
  }
`;

export const SPAN = styled(motion.span) <ObjectsExtraProps>`
  ${fontBrolimoRegular};
  ${fontSize20px};
  ${black70Color};

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  >a{
    text-decoration: none;
  }

  &.career{
    &:hover{
      font-weight: bolder;
    } 
  }

`;

export const LoadingContainer = styled(motion.div) <ObjectsExtraProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  
  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

`;

export const Loader = styled.div<ObjectsExtraProps>`
  width: 50px;
  height: 50px;
  border: 2px solid #53CAFF;
  border-top-color: #fff;
  border-radius: 50%;
  animation: ${spinAnimation} 1.5s linear infinite;
  margin-right: 10px;

  ${({ extrastyles }) =>
    extrastyles &&
    Object.entries(extrastyles)
      .map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value} !important;`)
      .join('')}

  @media (max-width: 768px) {// Mobile screen sizes
    ${({ extramobilestyles }) =>
    extramobilestyles &&
    Object.entries(extramobilestyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }

  @media (min-width: 769px) and (max-width: 1366px) { // Tablet and laptop screen sizes
    ${({ extratabletstyles }) =>
    extratabletstyles &&
    Object.entries(extratabletstyles).map(([key, value]) => `${key.replace(/([A-Z])/g, "-$1")
      .toLowerCase()}: ${value} !important;`).join('')}
  }
`;

export const LoadingText = styled.p`
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  ${fontBrolimoRegular};
`;

export const LoadingPageContainer = styled.div`
  ${flexContainerCenterCenter};
  ${width100per};
  ${height100Vh};
`;

export default GlobalStyle;

