import toast from "react-hot-toast";
import * as API from "../services/api";
import * as INTERFACES from "../interfaces";

// Function to encrypt the token using a secret key (you can use any secure encryption library)
export const encryptToken = (token: string): string => {
  // Implement your encryption logic here
  // For demonstration purposes, we'll use a simple base64 encoding as an example
  const encryptedToken = btoa(token);
  return encryptedToken;
};

// Function to store the encrypted access token securely (you can use local storage or other secure storage mechanisms)
export const storeAccessToken = (encryptedToken: string) => {
  const access_token = process.env.REACT_APP_REFRESH_TOKEN_NAME || "";
  localStorage.setItem(access_token, encryptedToken);
};

// Function to store the encrypted refrsh token securely (you can use local storage or other secure storage mechanisms)
export const storeRefrshToken = (encryptedToken: string) => {
  const refresh_token = process.env.REACT_APP_ACCESS_TOKEN_NAME || "";
  localStorage.setItem(refresh_token, encryptedToken);
};

// Function to retrieve the encrypted access token from storage
const getEncryptedAccessTokenFromStorage = (): string | null => {
  const access_token = process.env.REACT_APP_REFRESH_TOKEN_NAME || "";
  return localStorage.getItem(access_token);
};

// Function to retrieve the encrypted refresh token from storage
const getEncryptedRefreshTokenFromStorage = (): string | null => {
  const refresh_token = process.env.REACT_APP_ACCESS_TOKEN_NAME || "";
  return localStorage.getItem(refresh_token);
};

// Function to decrypt the token using the same secret key (you can use any secure decryption library)
const decryptToken = (encryptedToken: string): string => {
  // Implement your decryption logic here
  // For demonstration purposes, we'll use a simple base64 decoding as an example
  const decryptedToken = atob(encryptedToken);
  return decryptedToken;
};

// Function to get the access token in its decrypted form
export const getAccessToken = (): string | null => {
  const encryptedToken = getEncryptedAccessTokenFromStorage();
  if (encryptedToken) {
    return decryptToken(encryptedToken);
  }
  return null;
};

// Function to get the expiration time of the access token
export const getAccessTokenExpiration = (
  accessToken: string
): number | null => {
  try {
    // Decode the access token to extract the expiration time
    const tokenParts = accessToken.split(".");
    if (tokenParts.length === 3) {
      const payload = JSON.parse(atob(tokenParts[1]));
      if (payload && payload.exp) {
        // The expiration time is in seconds, convert it to milliseconds
        return payload.exp * 1000;
      }
    }
  } catch (error) {
    console.error("Error decoding access token:", error);
  }
  return null;
};

// Function to remove the access token from storage
export const removeAccessToken = () => {
  const access_token = process.env.REACT_APP_ACCESS_TOKEN || "";
  localStorage.removeItem(access_token);
};

// Function to refresh the access token
export const refreshAccessToken = async () => {
  const refreshToken = getEncryptedRefreshTokenFromStorage(); // Retrieve the refresh token from storage
  if (refreshToken) {
    try {
      const response = await API.refreshToken(refreshToken);
      if (!response.hasError) {
        removeAccessToken();
        const successResponse = response as INTERFACES.ResponseSuccess;
        if (response?.message === "Server works") {
          return null;
        } else {
          const access_token_key = successResponse.detail; // Get the new access token from the response
          const encryptedToken = encryptToken(access_token_key); // Encrypt the new access token
          storeAccessToken(encryptedToken); // Update the stored token with the new encrypted token
        }
      } else {
        const errorResponse = response as INTERFACES.ResponseError;
        toast.error(errorResponse.message);
        setTimeout(
          () =>
            toast(errorResponse.detail, {
              duration: 5000,
            }),
          2000
        );
        // Check if the error indicates that the refresh token is expired
        if (errorResponse.detail === "Please provide a valid refresh token") {
          window.location.href = "/login";
          return null;
        }
      }
    } catch (error: any) {
      console.error(error);
      toast.error("An error occurred while processing the request");
    }
  } else {
    toast.error("No refresh token available!");
  }
};

// Function to automatically refresh the token before it expires
export const setupTokenRefresh = (
  refresh_token_key: string,
  expirationTimeSeconds: number
) => {
  const encryptedToken = encryptToken(refresh_token_key); // Encrypt the refresh token
  storeRefrshToken(encryptedToken); // Update the stored refresh token with the new encrypted token
  // Convert expiration time from seconds to milliseconds
  const expirationTimeMs = expirationTimeSeconds * 1000;
  // Calculate the remaining time until token expiration
  const now = Date.now();
  const expiresInMs = expirationTimeMs - now;
  if (expiresInMs > 0) {
    const refreshTimer = setInterval(async () => {
      await refreshAccessToken();
    }, expiresInMs);
  }
  // Stop the refresh timer when needed (e.g., when the user logs out or the token is no longer needed)
  //clearInterval(refreshTimer);
};

// // Example usage of setting up token refresh with a timer
// const accessTokenExpirationTimeMs = 3600000; // Assuming the token expires in 1 hour
// setupTokenRefresh(accessTokenExpirationTimeMs);
